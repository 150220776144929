import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';
import {Context} from '../../AppContext';
import ProductConfig from '../../Components/ProductConfig';
import {
  getDefaultConfigWithExtra,
  SelectRow,
} from '../../Components/ProductParam';
import {Row, Col, Alert, message} from 'antd';
import BottomSection from './BottomSection';
import {LinkOutlined} from '@ant-design/icons';
// import * as AppEvents from "../AppEvents";
import {Text, Button, Divider, Heading} from '../../Widgets';
import RelatedProduct from './RelatedProduct';
import {navigate} from 'gatsby';
import ShareLink from '../../Components/ShareLink';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import Login, {metadata} from '../../Modals/Login';
import SizeConfig from '../../Components/SizeConfig';
import Tracking from '../../Tracking';
import Tag from '../../Components/ProductTag';
import ImageSection from './ImageSection';
import {ErrBase} from '../../errors';
const appConfig = require('../../data.json');

function getProductImages(product) {
  let images = [];
  if (product.image) {
    images.push({image: product.image});
  }

  return images
    .concat(product.images)
    .map((i) => ({image: i.image, name: i.name}));
}

function errorHandler(ex, defaultMsg = '加入購物車發生錯誤。') {
  console.warn(ex);
  if (ex instanceof ErrBase) {
    message.warning(ex.message);
  } else if (ex.error === 'items_limit') {
    message.error('購物車數量已滿。');
  } else {
    message.error(defaultMsg);
  }
}

export default function ProductDetail(props) {
  const [loading, setLoading] = useState({
    main: true,
    price: false,
    cart: false,
    redirectCart: false,
  });
  const [productInSpec] = useState(props.pageContext.product);
  const [initialConfigValues, initialConfigExtra] = React.useRef(
    getDefaultConfigWithExtra({product: productInSpec}),
  ).current;
  const [config, setConfig] = useState({
    values: initialConfigValues,
    extra: initialConfigExtra,
  });
  const [product, setProduct] = useState(null);
  const [meta, setMeta] = useState({
    price: null,
    originalAmount: null,
    requireDay: null,
  });
  const app = useContext(Context);
  const outOfStock = product?.is_out_stock;

  function _setLoading(obj) {
    setLoading((values) => ({...values, ...obj}));
  }

  const copyShareLink = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      message.success('複製分享連結成功。');
    } catch (err) {
      console.warn('err');
      message.error('複製分享連結失敗，請稍後再試。');
    }
  }, []);

  const onConfigChange = useCallback(
    async (values, extra = {}) => {
      app.actions.setLoading(true);
      _setLoading({price: true});

      try {
        const itemConfig = {
          name: productInSpec.name,
          config: values,
        };

        let resp = await app.actions.calcPrice(itemConfig);
        setMeta({
          price: resp.price,
          // calc original amount
          originalAmount:
            resp.quantity && extra.original_price
              ? resp.quantity * extra.original_price
              : null,
          requireDay: resp.require_day,
        });
        setConfig((prev) => ({
          values,
          extra: {
            ...prev.extra,
            ...extra,
          },
        }));
      } catch (ex) {
        console.warn(ex);
      }

      _setLoading({price: false});
      app.actions.setLoading(false);
    },
    [app.actions, productInSpec.name],
  );

  const addToCart = async ({redirect = false, price} = {}) => {
    if (!app.state.profile) {
      app.actions.setModal({
        content: <Login />,
        ...metadata,
      });
      _setLoading({cart: false, redirectCart: false});
      return;
    }

    if (JSON.stringify(app.state.specsError) !== '{}') {
      message.error('規格錯誤，請重新選擇');
      _setLoading({cart: false, redirectCart: false});
      return;
    }

    try {
      const item = {
        product: product.id,
        config: config.values,
        price, // this price is for facebook conversion api
      };

      await app.actions.addItem(item);

      if (redirect) {
        navigate('/cart');
      }
    } catch (ex) {
      errorHandler(ex);
    }
    _setLoading({cart: false, redirectCart: false});
  };

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      _setLoading({main: true});

      try {
        const resp = await app.actions.getProductByName(productInSpec.name);
        setProduct(resp);

        // calculate the default price
        await onConfigChange(initialConfigValues, initialConfigExtra);
      } catch (err) {
        console.warn(err);
      }

      _setLoading({main: false});
      app.actions.setLoading(false);
    })();
  }, [
    app.actions,
    onConfigChange,
    productInSpec.name,
    initialConfigValues,
    initialConfigExtra,
  ]);

  useEffect(() => {
    Tracking.addImpression(productInSpec, app.state.profile?.id);
  }, [productInSpec, app.state.profile]);

  const [period, totalOfPeriod] = useMemo(() => {
    if (productInSpec.is_period) {
      console.log('meta.price', meta.price);
      console.log('config.values.period_select', config.values.period_select);

      let option = productInSpec.period_select.options.find(
        (o) => o.name === config.values.period_select,
      );
      if (option) {
        return [option.period, meta.price * option.period];
      }
    }
    return [];
  }, [config.values, productInSpec, meta.price]);

  if (loading.main) {
    return (
      <ContentWrapper>
        <div />
      </ContentWrapper>
    );
  }

  if (!product) {
    navigate('/products');
    return (
      <ContentWrapper>
        <Alert message="此商品不存在" />
      </ContentWrapper>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <script type="application/ld+json">{`
        {
            "@context": "http://schema.org",
            "@type": "Product",
            "name": "${productInSpec.name}",
            "image": [${product.images.map((image) => `"${image.image}"`)}],
            "description": "${product.description}",
            "sku": "${product.id}",
            "brand": {
                "@type": "Brand",
                "name": "理想印制"
            },
            "offers": {
                "@type": "Offer",
                "price": "${product.price}",
                "priceCurrency": "TWD",
                "priceValidUntil": "2024-12-31",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock"
            },
            "additionalProperty": [
              {
                "@type": "PropertyValue",
                "name": "Hashtag",
                "value": "${product.hashtag}"
              },
              {
                "@type": "PropertyValue",
                "name": "Tag",
                "value": "${product.tag}"
              }
            ]
          }
    `}</script>
      </Helmet>
      <ContentWrapper>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12}>
            <ImageSection images={getProductImages(product)} />
            <Text
              size="sm"
              color={appConfig.colors.textThird}
              style={{
                marginTop: 15,
                marginRight: 15,
                marginLeft: 15,
                marginBottom: 15,
              }}>
              {product.description}
            </Text>
            <ShareLink
              item={product}
              pluginAction={() => copyShareLink()}
              extraStyle={{borderTop: 0, padding: 0, marginLeft: 15}}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={1} />

          <Col xs={24} sm={24} md={24} lg={11}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Heading color={appConfig.colors.second} style={{margin: 0}}>
                {productInSpec.name}
              </Heading>
              <Tag
                product={product}
                freeShipping={productInSpec.free_shipping}
                onItem={false}
                isPeriod={productInSpec.is_period}
              />
            </div>
            <Divider />

            <div>
              <ProductConfig
                product={productInSpec}
                config={config.values}
                requireDay={meta.requireDay}
                onChange={(nextConfig, nextExtra) =>
                  onConfigChange(nextConfig, nextExtra)
                }
              />

              <SizeConfig product={productInSpec} config={config.values} />

              {config.extra && (
                <SelectRow style={{alignItems: 'baseline'}}>
                  <label>開版日</label>
                  <div className="select-options">{`星期 ${
                    (!loading.price && config.extra.open_template_day) || '---'
                  } `}</div>
                </SelectRow>
              )}

              {/* <SelectRow style={{alignItems: 'baseline'}}>
                <label>製作時間</label>
                <Text
                  size="lg"
                  color={appConfig.colors.main}
                  style={{marginRight: 5}}>{` ${
                  (!loading.price && meta.requireDay) || '---'
                } `}</Text>
                <Text size="xs">
                  天（審稿成功後隔日起算工作天，工作天不含假日及運送時間）
                </Text>
              </SelectRow> */}

              {product.file_upload && (
                <SelectRow style={{alignItems: 'baseline'}}>
                  <label>傳檔說明</label>
                  <div className="select-options">
                    訂單成立後可上傳客製化檔案，並備註製作說明。
                  </div>
                </SelectRow>
              )}
            </div>

            <Divider />

            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                marginBottom: 10,
              }}>
              <Text
                weight="600"
                color={appConfig.colors.error}
                style={{marginRight: 10}}>
                促銷價格
              </Text>
              {/* price from spec by Api calc*/}
              <Text
                weight="600"
                color={appConfig.colors.error}
                style={{fontSize: 30}}>
                {' $'}
                {(!loading.price && meta.price) || '---'}{' '}
              </Text>
              <Text
                weight="600"
                color={appConfig.colors.error}
                style={{marginRight: 20}}>
                元 / 含稅
              </Text>

              {/* originalAmount from spec by FE calc*/}
              {/* {originalAmount && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        color: "#a0a0a0",
                      }}
                    >
                      <label style={{ marginRight: 10 }}>原價</label>
                      <div
                        style={{ textDecoration: "line-through" }}
                      >{` \$${(!loadingPrice && originalAmount) ||
                        "---"} 元`}</div>
                    </div>
                  )} */}
            </div>

            {/* 訂閱制金額試算 */}
            {productInSpec.is_period && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  marginBottom: 10,
                }}>
                <Text size="sm">
                  {' '}
                  {'$ '}
                  {totalOfPeriod} 元 / {period} 期 = {'每期優惠金額 $ '}
                  {meta.price} 元{' '}
                </Text>
              </div>
            )}

            <div style={{display: 'flex', marginBottom: 20}}>
              <Button
                loading={loading.redirectCart}
                disabled={
                  loading.cart ||
                  loading.redirectCart ||
                  loading.price ||
                  outOfStock
                }
                onClick={async () => {
                  _setLoading({redirectCart: true});
                  await addToCart({redirect: true, price: meta.price});
                }}
                size="large"
                style={{
                  backgroundColor: appConfig.colors.second,
                  borderColor: appConfig.colors.second,
                }}
                block>
                {outOfStock ? '商品已售完 ' : '立即購買'}
              </Button>

              <div style={{flexBasis: 20}} />

              <Button
                loading={loading.cart}
                disabled={
                  loading.cart ||
                  loading.redirectCart ||
                  loading.price ||
                  outOfStock
                }
                onClick={async () => {
                  _setLoading({cart: true});
                  await addToCart({redirect: false, price: meta.price});
                }}
                size="large"
                block>
                加入購物車
              </Button>
            </div>

            <RelatedProduct id={product.related_product} />
          </Col>
        </Row>
      </ContentWrapper>

      <BottomSection product={product} />
    </Fragment>
  );
}
